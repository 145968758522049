<template>
    <CCardBody class="d-flex flex-column">
        <CRow>
            <CCol>								
                <h1>Create a New Password</h1>
            </CCol>
        </CRow>
        <CRow>
            <CCol>								
                <p class="text-muted">Set your new password. Password rules: at least 6 characters, uppercase and lowercase letters, one or more digits, one or more of these special characters: @,$,!,%,*,#,?,&</p>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="7">
                <CForm @submit.prevent="submit">
                    <CInput placeholder="Password" type="password" maxLength="24"     
                            style="height: 42px"
                            v-model="password" 
                            autocomplete="new-password"
                            :disabled="loading"
							:lazy="true"
							:value.sync="$v.form.password.$model"
							:isValid="checkIfValid('password')"
							invalidFeedback="Password doesn't satisfy password rules."                     
                    >
                        <template #prepend-content
                            ><CIcon name="cil-lock-locked"
                        /></template>
                    </CInput>
                    <CInput placeholder="Confirm Password" type="password" maxLength="24"     
                            style="height: 42px"
                            v-model="confirm"          
                            :disabled="loading"   
                            :lazy="false"       
                            :value.sync="$v.form.confirm.$model"    
                            :isValid="checkIfValid('confirm')" 
                            invalidFeedback="Password confirmation doesn't match password"    
                    >
                        <template #prepend-content
                            ><CIcon name="cil-lock-locked"
                        /></template>
                    </CInput>
                    <div class="d-flex flex-row">
                        <CButton color="primary" type="submit" :disabled="loading"><CSpinner v-if="loading" size="sm" class="mr-2"></CSpinner>Set Password</CButton> 
                        <CLink v-if="!skipLogin" style="margin-left: auto" class="mr-1 align-self-center" to="login" :disabled="loading">Back to Login</CLink>   
                    </div>
                </CForm>
            </CCol>
        </CRow>
        <CRow class="justify-self-end" style="margin-top: auto;">
            <CCol>
                <CAlert :show="true" color="danger" :fade="false" :class="['mt-3', 'mb-2', errorMessage ? 'visible' : 'invisible']">{{ errorMessage }}</CAlert>
            </CCol>
        </CRow>		
    </CCardBody>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapActions, mapGetters } from 'vuex';
import { authAction } from '@/store/modules/auth';
import { getResponseData, getResponseStatus, getErrorMessage } from '@/utils';
import router from '@/router';

export default {
    props: {
        loginLink: Boolean
    },
    created() {
        if (!this.email) router.replace('/password');
        if (!this.resetToken) router.replace('/verify');
    },
    data() {
        return {
            form: this.getEmptyForm(),
            loading: false,
            password: null,
            confirm: null,
            errorMessage: null
        }
    },
    computed: {
        ...mapGetters(['email', 'resetToken']),
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
        skipLogin() {
            return this.loginLink === false || this.loginLink === 'false';
        }
    },
    methods: {
        ...mapActions({
            setPassword: authAction.SET_PASSWORD
        }),
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName];
            if (!field.$dirty) {
                return null
            } 
			if (fieldName == 'password') {
                const p = field.$model;
				if (!field.$model || field.$model.length < 6 || !/[a-z]+/.test(p) || !/[A-Z]+/.test(p) || !/[0-9]+/.test(p) || !/[@$!%*#?&]+/.test(p)) return false;
			}
            return (field.$invalid || field.$model === '') ? false : null;            
        },
        getEmptyForm()
        {
            return {
                password: null,
                confirm: null
            }
        },
        async submit() {
            this.$v.form.password.$touch();
            this.$v.form.confirm.$touch();

            if (!this.isValid) {
                return;
            }
            this.loading = true;

            try {
                await this.setPassword({email: this.email, token: this.resetToken, password: this.password});
                router.replace('/password-changed');
            }
            catch(e) {   
                console.log("Catch");
                const status = getResponseStatus(e);
                const data = getResponseData(e);
                console.log({status,data});
                if (status == 400 && data.errorCode == 1 && data.errors) {
                    const errors = data.errors.map(o => o.description);
                    this.errorMessage = "Invalid password: " + errors.join('. ');
                }
                else {
                    this.errorMessage = getErrorMessage(e);
                }
            }

            this.loading = false;            
        }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirm: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    }
}
</script>
